h1, h2, h3, h4, h5, h6, p{
    color: black;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
}
p{
    font-size: 0.9vw;
}
h1{
    font-size: 10vw;
}
h2{
    font-size: 5vw;
}
h3{
    font-size: 4vw;
}
h4{
    font-size: 2vw;
}
h5{
    font-size: 1.5vw;
}
h6{
    font-size: 1vw;
}


.mainContainer.weatherForecast{
    height: auto;
    width: 100%;
    background: white;
}