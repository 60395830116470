h1, h2, h3, h4, h5, h6, p{
    color: black;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
}
p{
    font-size: 0.9vw;
}
h1{
    font-size: 10vw;
}
h2{
    font-size: 5vw;
}
h3{
    font-size: 4vw;
}
h4{
    font-size: 2vw;
}
h5{
    font-size: 1.5vw;
}
h6{
    font-size: 1vw;
}


.mainContainer.breakingNews{
    height: auto;
    width: 100%;
    background: white;
}



section.tduBreakingNewsPage.top{
    height: auto;
    width: 100%;
    padding: 6% 5%;
}
.brnPageContainerTop{
    height: auto;
    width: 100%;
    padding: 1% 0;
}
.sliderContainer{
    height: auto;
    width: 100%;
}
.sliderFeat{
    height: 20vw;
    width: 100%;
    border-radius: 2vw;
}
.homeSlider{
    height: 20vw;
}
div.awssld.awssld--organic-arrows{
    height: auto;
}
div.awssld__wrapper{
    height: 20vw;
    border-radius: 1vw;
    border: solid 3px gray;
}
div.awssld__container {
    padding-bottom: 0;
}
div.awssld__box{
    height: auto;
}
div.awssld__content{
    height: auto;
}
nav.awssld__bullets{
    visibility: hidden;
}
.featContent{
    position: relative;
}
.featContent img{
    height: 20vw;
    max-width: 100%;
}





.brnPageContainerTop1{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.brnpContent.left{
    height: auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.brnpContent.left a{
    height: auto;
    width: 65%;
    text-decoration: none;
}
.brnpFeatured{
    height: auto;
    width: 100%;
}
.brnpFeatured img{
    height: auto;
    width: 100%;
    border-radius: 1vw;
}
.brnpFeatured h5{
    font-style: italic;
    font-weight: 800;
}
.brnpSubFeatured{
    height: auto;
    width: 33%;
}
.brnpSubFeatured h5{
    font-style: italic;
    margin-bottom: 3%;
    font-weight: 800;
}
.brnpSubFeatured hr{
    margin-bottom: 5%;
}
.brnpSubFeatured h6{
    margin-bottom: 3%;
}






.brnPageContainerMid{
    height: auto;
    width: 100%;
    margin: 2% 0;
}
.brnPageContainerMid h4{
    font-weight: 900;
    font-size: 3vw;
    font-style: italic;
}
.brnpSpotlightContainer{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 3% 0;
}
.brnpSpotlightContainer a{
    height: auto;
    width: 48%;
    text-decoration: none;
}
.brnpsContent{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.brnpsc.left{
    height: auto;
    width: 45%;
}
.brnpsc.left p{
    margin-top: 2%;
}
.brnpsc.right{
    height: auto;
    width: 54%;
}
.brnpsc.right img{
    height: auto;
    width: 100%;
    border-radius: 1vw;
}



.brnPageContainerMid1{
    height: auto;
    width: 100%;
    margin: 2% 0;
}
.brnPageContainerMid1 h4{
    font-weight: 900;
    font-size: 3vw;
    font-style: italic;
}
.brnpWorldLatestContainer{
    height: auto;
    width: 100%;
    margin: 3% 0;
    display: flex;
    justify-content: space-between;
}
.brnpWLContent{
    height: auto;
    width: 19%;
}
.brnpWLContent p{
    margin-top: 2%;
}
.brnPageContainerMid1 a{
    text-decoration: none;
}
.brnPageContainerMid1 span h4{
    font-weight: 700;
}
.brnPageContainerMid1 span p{
    margin-top: 1%;
}


.brnPageContainerMid2{
    height: auto;
    width: 100%;
    margin: 2% 0;
}
.brnPageContainerMid2 h4{
    font-weight: 900;
    font-size: 3vw;
    font-style: italic;
}
.brnpUnitedStatesContainer{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 3% 0;
}
.brnpUnitedStatesContainer a{
    height: auto;
    width: 24%;
    text-decoration: none;
}
.brnpUSContent{
    height: auto;
    width: 100%;
}
.brnpUSContent img{
    height: auto;
    width: 100%;
    border-radius: 1vw;
}




.brnPageContainerBottom{
    height: auto;
    width: 100%;
    margin: 2% 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.brnPageContainerBottom h4{
    font-weight: 900;
    font-size: 3vw;
    font-style: italic;
}
.brnPageContainerBottom a{
    text-decoration: none;
}
.brnpAnchorLinks{
    height: auto;
    width: 47%;
    margin: 1%;
}
.brnpAnchorLinks img{
    height: 5vw;
}
.brnpAnchorLinks p{
    margin: 2% 0;
}
.brnpAnchorLinks span{
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}
.brnpAnchorLinks span a{
    width: 32%;
    margin: 2% 0;
    opacity: 50%;
}
.brnpAnchorLinks span a:hover h6{
    color: red;
    opacity: 100%;
    transition: 400ms ease;
    font-weight: 900;
}
.brnPageContainerBottom hr{
    margin: 2% 0;
    border: solid 2px black;
}