@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800&display=swap');


/* font-family: 'Kanit', sans-serif; */

h1, h2, h3, h4, h5, h6, p{
    color: black;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
}
p{
    font-size: 0.9vw;
}
h1{
    font-size: 10vw;
}
h2{
    font-size: 5vw;
}
h3{
    font-size: 4vw;
}
h4{
    font-size: 2vw;
}
h5{
    font-size: 1.5vw;
}
h6{
    font-size: 1vw;
}


.mainContainer.home{
    height: auto;
    width: 100%;
    background: white;
}

section.landingPageTDU.top{
    height: 100vh;
    width: 100%;
    background: url('../assets/imgs/TheDailyUniverseBG.png') no-repeat center;
    background-size: cover;
    position: relative;
}
.ltuWorldMap{
    height: 100vh;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    top: 0;
    left: 0;
    z-index: 2;
    padding: 8% 3%;
    padding-bottom: 3%;
}
.ltuwContainer{
    height: 100%;
    width: 100%;
    border: solid 5px gray;
    border-radius: 1vw;
    background: url('../assets/imgs/TheDailyUniverseBG.png') no-repeat center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ltuwContainer button#closeMap{
    position: absolute;
    right: 2%;
    top: 3%;
    background: none;
    border: none;
    cursor: pointer;
}
.ltuwContainer button#closeMap h6{
    font-size: 2vw;
}
.ltuwContainer button#closeMap:hover h6{
    color: red;
    transition: 600ms ease;
}


.landingTDUTop{
    height: 100%;
    width: 100%;
    padding: 6vw 3%;
    padding-bottom: 2vw;
    display: flex;
    justify-content: space-between;
}
.ltduTop.left{
    height: 100%;
    width: 100%;
    border: solid 3px gray;
    border-radius: 2vw;
    background: url('../assets/imgs/TheDailyUniverseBG.png') no-repeat left;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.mapChange{
    height: auto;
    position: absolute;
    top: 4%;
    right: 0%;
    width: 5%;
}
.mapChange button{
    border: solid 2px black;
    padding: 0.5vw;
    margin: 3% 1%;
    border-radius: 0.5vw;
    cursor: pointer;
}
.mapChange button:hover{
    background: skyblue;
    transition: 600ms ease;
}
.mapChange button h6{
    font-size: 1.5vw;
    line-height: 1vw;
}
/* .ltduTop.right{
    height: 100%;
    width: 33%;
    padding: 1% 0;
    position: relative;
}
.ltduTop.right h4{
    font-size: 2vw;
    font-weight: 800;
    font-style: italic;
}
.breakingNews{
    height: 88%;
    width: 100%;
    overflow-y: scroll;
}
.worldBreakingNews{
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.worldBreakingNews .wbNews{
    height: auto;
    width: 95%;
    background: white;
    border-radius: 1vw;
    border: solid 5px gray;
    margin: 1%;
    overflow: hidden;
    text-align: center;
}
.worldBreakingNews .wbNews img{
    height: 12vw;
    width: 100%;
    border-bottom: solid 5px gray;
}
.worldBreakingNews .wbNews p{
    padding: 2%;
}
.ltduTop.right button{
    width: 30%;
    background: none;
    border: none;
    outline: none;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 50%;
}
.ltduTop.right button:hover{
    opacity: 100%;
    transition: 600ms ease;
}
.ltduTop.right button h6{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
}
.ltduTop.right button h6 .faIcon{
    margin-left: 5%;
} */


section.landingPageTDU.mid{
    height: auto;
    width: 100%;
    padding-bottom: 10%;
}
.landingTDUMid1{
    height: auto;
    width: 100%;
    padding: 1% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.landingTDUMid1 div span{
    margin: 0 1%;
}
.landingTDUMid1 div span h5 .faIcon.curUp{
    color: green;
}
.landingTDUMid1 div span h5 .faIcon.curDown{
    color: red;
}
.dollarExchange{
    height: auto;
    width: 100%;
    text-align: center;
}
.exchangeDetails{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.landingTDUMid2{
    height: auto;
    width: 100%;
    padding: 2% 5%;
    padding-top: 3%;
}
.landingTDUMid2 h4{
    font-size: 3vw;
    font-weight: 800;
    font-style: italic;
    display: flex;
    justify-content: left;
    align-items: center;
}
.landingTDUMid2 h4 .faIcons{
    margin-right: 1%;
}
.lndBreakNews.container{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 0;
}
.lndBreakNewsContent.left{
    height: auto;
    width: 45%;
    border-radius: 1vw;
    overflow: hidden;
    box-shadow: 0 0 20px gray;
    text-align: center;
    cursor: pointer;
}
.lndBreakNewsContent.left:hover{
    box-shadow: 0 0 30px black;
    transition: 500ms ease;
}
.lndBreakNewsContent.left img{
    height: 25vw;
    width: auto;
}
.lndbnLeftContent{
    height: auto;
    width: 100%;
    padding: 3%;
    text-align: center;
}
.lndbnLeftContent p{
    margin-top: 1%;
}
.lndBreakNewsContent.right{
    height: auto;
    width: 53%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.lndbnRightContent{
    height: 16vw;
    width: 31%;
    margin: 1%;
    text-align: center;
    /* box-shadow: 0 0 15px gray; */
    border-radius: 1vw;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}
.lndbnRightContent:hover{
    box-shadow: 0 0 15px black;
    transition: 500ms ease;
}
.lndbnRightContent img{
    height: auto;
    width: 100%;
}
.lndbnRightContent div{
    padding: 3% 5%;
    text-align: left;
}
.lndbnRightContent div h6{
    font-size: 1.2vw;
}




.landingTDUMid3{
    height: auto;
    width: 100%;
    padding: 2% 5%;
}
.landingTDUMid3 h4{
    font-size: 3vw;
    font-weight: 800;
    font-style: italic;
    display: flex;
    justify-content: left;
    align-items: center;
}
.landingTDUMid3 h4 .faIcons{
    margin-right: 1%;
}
.landingTDUMid3 h5{
    font-weight: 500;
}
.lndTrendingNews.container{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 2% 0;
}
.lndtnContent{
    height: auto;
    width: 23%;
    border-radius: 1vw;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}
.lndtnContent img{
    height: auto;
    width: 100%;
}
.lndtnContent div{
    height: auto;
    width: 100%;
    padding: 2%;
}
.lndtnContent div h6{
    font-size: 1.2vw;
    text-align: left;
}




.landingTDUMid4{
    height: auto;
    width: 100%;
    padding: 2% 5%;
}
.landingTDUMid4 h3{
    font-size: 5vw;
    font-weight: 800;
    font-style: italic;
    display: flex;
    justify-content: left;
    align-items: center;
}
.lndSportsContent{
    height: auto;
    width: 100%;
    padding: 3%;
    position: relative;
    background: goldenrod;
    border-radius: 1vw;
    margin-bottom: 2%;
}
.lndSportsContent h4{
    font-size: 9.5vw;
    line-height: 5.5vw;
    font-weight: 900;
    font-style: italic;
    font-family: 'Kanit', sans-serif;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.2vw;
    -webkit-text-stroke-color: black;
    position: relative;
    z-index: 1;
}
.lndSportsContent h4 span{
    font-size: 7vw;
    line-height: 6vw;
    font-weight: 900;
    font-style: italic;
    font-family: 'Kanit', sans-serif;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.2vw;
    -webkit-text-stroke-color: black;
    position: relative;
}
.lndSportsContent h5{
    font-size: 3vw;
}
.lndSportsContent img{
    position: absolute;
    height: 30vw;
    bottom: 0;
    right: 0;
    border-bottom-right-radius: 1vw;
}


.landingTDUMid5{
    height: auto;
    width: 100%;
    padding: 2% 5%;
    padding-bottom: 5%;
}
.landingTDUMid5 h4{
    font-size: 3vw;
    font-weight: 800;
    font-style: italic;
    display: flex;
    justify-content: left;
    align-items: center;
}
.landingTDUMid5 h4 .faIcons{
    margin-right: 1%;
}
.landingTDUMid5 h5{
    font-weight: 500;
}
.lndTravelContainer{
    height: 30vw;
    width: 100%;
    position: relative;
    border-radius: 2vw;
    overflow: hidden;
    padding: 2%;
    margin: 2% 0;
}
.lndTravelContainer img#travelBG{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.lndTravelContent{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.lndtContent.left{
    height: auto;
    width: 50%;
    text-align: left;
    position: relative;
}
.lndtContent.left h4{
    font-size: 5vw;
    line-height: 4vw;
    font-weight: 800;
}
.lndtContent.left h5{
    margin-top: 2%;
    font-weight: 700;
    font-size: 2vw;
    display: flex;
    justify-content: left;
    align-items: center;
}
.lndtContent.left h5 .faIcons{
    margin-right: 2%;
}
.lndtContent.right{
    height: 100%;
    width: 50%;
    text-align: right;
    position: relative;
}
.lndtContent.right img{
    height: 10vw;
}
.lndtContent.right p{
    position: absolute;
    bottom: 0;
    color: white;
}



.landingTDUMid6{
    height: auto;
    width: 100%;
    padding: 2% 5%;
    padding-bottom: 5%;
}
.landingTDUMid6 h5{
    font-size: 2vw;
    font-weight: 400;
    text-align: center;
}
.lndTDUFeedback{
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 3% 0;
}
.avatarFeedback{
    height: auto;
    width: 45%;
    margin: 2%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.avatarFeedback img{
    height: 10vw;
}
.avatarFeedback span{
    height: auto;
    width: 70%;
}
.avatarFeedback span h6{
    font-size: 2vw;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatarFeedback span h6 .faIcons{
    margin: 0 1%;
    color: gold;
}
.avatarFeedback span p{
    margin-top: 2%;
}




.landingTDUMid7{
    height: auto;
    width: 100%;
    padding: 2% 10%;
    padding-bottom: 5%;
}
.lndContactForm{
    height: 25vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 20px gray;
    border-radius: 1vw;
    padding: 2%;
    background: url('../assets/imgs/TheDailyUniverseBG.png') no-repeat center;
    background-size: cover;
}
.lndContactForm hr{
    height: 21vw;
    border: solid 1px gray;
}
.lndcfContent.left{
    height: auto;
    width: 40%;
    position: relative;
}
.lndcfContent.left h5{
    font-size: 2.5vw;
    font-weight: 800;
}
.lndcfContent.left span{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: left;
    align-items: center;
}
.lndcfContent.left span a{
    margin-right: 5%;
}
.lndcfContent.left span h6{
    font-size: 2vw;
}
.lndcfContent.right{
    height: auto;
    width: 55%;
    position: relative;
    overflow: hidden;
}
.tduContactForm{
    height: auto;
    width: 100%;
    padding: 5% 3%;
    position: relative;
}
.tduContactForm input{
    padding: 0.5vw;
    border: none;
    border-bottom: solid 1px gray;
    width: 45%;
    background: none;
    outline: none;
    margin: 1% 2%;
    font-size: 0.9vw;
}
.tduContactForm textarea{
    height: 10vw;
    padding: 0.5vw;
    border: solid 1px gray;
    width: 95%;
    background: none;
    outline: none;
    margin: 1% 2%;
    font-size: 0.9vw;
    resize: none;
}
.tduContactForm button{
    position: absolute;
    right: 5%;
    bottom: -3%;
    padding: 0.5vw 2vw;
    background: none;
    border-radius: 50px;
    border: solid 2px black;
    cursor: pointer;
}










.viewMoreButton{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}
.viewMoreButton a{
    width: 10%;
    opacity: 50%;
}
.viewMoreButton a:hover{
    opacity: 100%;
    transition: 500ms ease;
}
.viewMoreButton a button{
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
}
.viewMoreButton a button h6{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
}
.viewMoreButton a button h6 .faIcons{
    margin-left: 7%;
    animation: fade-in-left 1000ms ease-in-out infinite;
}



@keyframes fade-in-right {
    0% {
      opacity: 0;
      transform: translateX(80px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
}

@keyframes fade-in-left {
    0% {
      opacity: 0;
      transform: translateX(-10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
}