h1, h2, h3, h4, h5, h6, p{
    color: black;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
}
p{
    font-size: 0.9vw;
}
h1{
    font-size: 10vw;
}
h2{
    font-size: 5vw;
}
h3{
    font-size: 4vw;
}
h4{
    font-size: 2vw;
}
h5{
    font-size: 1.5vw;
}
h6{
    font-size: 1vw;
}


.mainContainer.newsChannels{
    height: auto;
    width: 100%;
    background: white;
}




section.tduNewsChannelsPage.top{
    height: auto;
    width: 100%;
    padding: 6% 5%;
}
section.tduNewsChannelsPage.top h2{
    font-size: 3vw;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
}
.nscPageContainerTop{
    height: auto;
    width: 100%;
    padding: 1% 0;
}
.nscPageContainerTop img{
    height: auto;
    width: 100%;
    border-radius: 1vw;
    border: solid 2px gray;
    box-shadow: 0 0 10px black;
}


.nscPageContainerTop1{
    height: auto;
    width: 100%;
    margin: 2% 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}
.nscPageContainerTop1 a{
    text-decoration: none;
    width: 18%;
    height: auto;
    border-radius: 1vw;
    margin: 1%;
    overflow: hidden;
    box-shadow: 0 0 20px gray;
    opacity: 60%;
    border: solid 3px gray;
    padding-bottom: 1%;
}
.nscPageContainerTop1 a:hover{
    box-shadow: 0 0 20px black;
    transition: 400ms ease;
    opacity: 100%;
}
.nscPageContainerTop1 a img{
    height: auto;
    width: 100%;
}
.newsChannelContainer{
    height: auto;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newsChannelContainer h4{
    position: absolute;
    font-size: 7vw;
    color: white;
}
.nscPageContainerTop1 a span{
    text-align: center;
}
.nscPageContainerTop1 a span h4{
    margin-top: 2%;
    font-weight: 800;
    font-size: 1.7vw;
    font-style: italic;
}
.nscPageContainerTop1 a span h6{
    margin: 2% 0;
}