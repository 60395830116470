h1, h2, h3, h4, h5, h6, p{
    color: black;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
}
p{
    font-size: 0.9vw;
}
h1{
    font-size: 10vw;
}
h2{
    font-size: 5vw;
}
h3{
    font-size: 4vw;
}
h4{
    font-size: 2vw;
}
h5{
    font-size: 1.5vw;
}
h6{
    font-size: 1vw;
}


.mainContainer.newspapers{
    height: auto;
    width: 100%;
    background: white;
}


section.tduNewspaperPage.top{
    height: auto;
    width: 100%;
    padding: 6% 5%;
}
section.tduNewspaperPage.top h2{
    font-size: 3vw;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
}
.npcPageContainerTop{
    height: auto;
    width: 100%;
    padding: 1% 0;
}
.npcPageContainerTop img{
    height: auto;
    width: 100%;
    border-radius: 1vw;
    border: solid 2px gray;
    box-shadow: 0 0 10px black;
}
.npcPageContainerTop button{
    margin-top: 2%;
    background: none;
    border: none;
    cursor: pointer;
}
.npcPageContainerTop button h6{
    display: flex;
    justify-content: left;
    align-items: center;
}




/* .newsPaperNav{
    height: auto;
    width: 100%;
    margin: 1% 0;
    display: flex;
    justify-content: left;
    align-items: center;
}
.newsPaperNav button{
    margin-right: 2%;
    border: none;
    background: none;
    outline: none;
    opacity: 30%;
    cursor: pointer;
    padding: 0.5vw;
}
.newsPaperNav button.active{
    opacity: 100%;
    border-bottom: solid 3px black;
}
.newsPaperNav button:hover{
    opacity: 100%;
    transition: 600ms ease;
} */


.npContainers{
    height: auto;
    width: 100%;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}
.npContainers a{
    height: auto;
    width: 23%;
    cursor: pointer;
    margin: 1%;
    opacity: 50%;
    border-radius: 1vw;
    border: solid 1px black;
    text-decoration: none;
}
.npContainers a:hover{
    opacity: 100%;
    transition: 400ms ease;
    box-shadow: 0 0 10px black;
}
.npalContent img{
    height: auto;
    width: 100%;
    border-radius: 1vw;
}
.npalContent p{
    padding: 5% 3%;
}


.newsPaperContent{
    height: auto;
    width: 100%;
    padding: 6% 5%;
}
.altNewsContent{
    height: auto;
    width: 100%;
}


.altNewsContent h2{
    font-weight: 800;
    text-align: center;
    font-size: 3vw;

}
.altNewsContent h6{
    text-align: center;
}



a.npAltNewsContent,
a.npCollegeNewsContent,
a.npWorldNewsContent,
a.npCuntryNewsContent{
    height: auto;
    width: 23%;
    text-decoration: none;
    opacity: 50%;
    margin: 1%;
    padding: 1%;
    border-radius: 1vw;
}
a.npAltNewsContent:hover,
a.npCollegeNewsContent:hover,
a.npWorldNewsContent:hover,
a.npCuntryNewsContent:hover{
    opacity: 100%;
    transition: 400ms ease;
    box-shadow: 0 0 10px black;
}
a.npAltNewsContent h4,
a.npCollegeNewsContent h4,
a.npWorldNewsContent h4,
a.npCuntryNewsContent h4{
    font-size: 1.5vw;
}


.npContainers.localNews{
    height: auto;
    width: 100%;
    display: block;
}
.searchLocalNews{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
}
.searchLocalNews input{
    padding: 0.5vw 1%;
    margin-right: 1%;
    font-size: 1vw;
    width: 50%;
    border-radius: 1vw;
    border: none;
    background: rgb(216, 216, 216);
}
.searchLocalNews button{
    padding: 0.5vw;
    border: none;
    background: transparent;
    cursor: pointer;
}
.searchLocalNews button h6{
    font-size: 1vw;
}
.lcNewspapers{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: left;
    margin: 3% 0;
}
.npContainers ul h4{
    font-size: 1.5vw;
    font-weight: 800;
    font-style: italic;
}
.npContainers ul{
    list-style: none;
    margin: 1% 2%;
}

.npContainers ul li{
    display: flex;
    justify-content: left;
    align-items: center;
}
.npContainers ul li a{
    width: auto;
    text-decoration: none;
    padding: 2% 5%;
    margin: 2% 0;
    border-radius: 0.5vw;
}
.npContainers ul li a h6{
    font-size: 1.2vw;
    display: flex;
    justify-content: left;
    align-items: center;
}
.npContainers ul li a h6 .faIcons{
    margin-right: 5%;
    font-size: 1.5vw;
}


.wrapper {
    display:flex;
    justify-content: center;
    align-items: center;
}

.accordion {
    width: 500px;
}

.item {
    margin-bottom: 5px;
    padding: 10px 20px
}
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}