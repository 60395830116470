@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap');

/* font-family: 'Bruno Ace SC', cursive; */
h6{
    font-family: 'Montserrat', sans-serif;
}

nav{
    height: auto;
    width: 100%;
    position: fixed;
    z-index: 999;
}

.loginContainer{
    height: 100vh;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 10% 37%;
    padding-bottom: 4%;
}
.loginContent{
    height: 100%;
    width: 100%;
    background: url('../assets/imgs/TheDailyUniverseBG.png')no-repeat left;
    background-size: cover;
    border-radius: 2vw;
    border: solid 2px gray;
    box-shadow: 0 0 30px black;
    padding: 5%;
    position: relative;
    text-align: center;
}
.loginContent button#closeLogin{
    position: absolute;
    right: 6%;
    background: none;
    border: none;
    cursor: pointer;
}
.loginContent button#closeLogin:hover h6{
    color: red;
    transition: 600ms ease;
}
.loginContent button#closeLogin h6{
    font-size: 2vw;
}
.loginContent img{
    height: 2.5vw;
    position: absolute;
    left: 5%;
}
.loginContent h4{
    margin-top: 33%;
    margin-bottom: 8%;
}
.loginContent input{
    padding: 0.5vw;
    width: 70%;
    margin: 1.5% 0;
    font-size: 1vw;
    border-radius: 0.5vw;
}
.loginContent button#login{
    padding: 0.5vw;
    width: 50%;
    margin-top: 5%;
    border-radius: 0.5vw;
    border: solid 1px black;
    cursor: pointer;
}
.loginContent button#login:hover{
    background: black;
    transition: 600ms ease;
    box-shadow: 0 0 10px black;
}
.loginContent button#login:hover h6{
    color: white;
    transition: 600ms ease;
}
.loginContent a p{
    margin: 3% 0;
}
.loginContent button#register{
    position: absolute;
    bottom: 5%;
    left: 32%;
    background: none;
    border: 0;
    cursor: pointer;
}






.registerContainer{
    height: 100vh;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 10% 37%;
    padding-bottom: 4%;
}
.registerContent{
    height: 100%;
    width: 100%;
    background: url('../assets/imgs/TheDailyUniverseBG.png')no-repeat left;
    background-size: cover;
    border-radius: 2vw;
    border: solid 2px gray;
    box-shadow: 0 0 30px black;
    padding: 5%;
    position: relative;
    text-align: center;
}
.registerContent button#closeLogin{
    position: absolute;
    right: 6%;
    background: none;
    border: none;
    cursor: pointer;
}
.registerContent button#closeLogin:hover h6{
    color: red;
    transition: 600ms ease;
}
.registerContent button#closeLogin h6{
    font-size: 2vw;
}
.registerContent img{
    height: 2.5vw;
    position: absolute;
    left: 5%;
}
.registerContent h4{
    margin-top: 33%;
    margin-bottom: 8%;
}
.registerContent input{
    padding: 0.5vw;
    width: 70%;
    margin: 1.5% 0;
    font-size: 1vw;
    border-radius: 0.5vw;
}
.registerContent button#login{
    padding: 0.5vw;
    width: 50%;
    margin-top: 5%;
    border-radius: 0.5vw;
    border: solid 1px black;
    cursor: pointer;
}
.registerContent button#login:hover{
    background: black;
    transition: 600ms ease;
    box-shadow: 0 0 10px black;
}
.registerContent button#login:hover h6{
    color: white;
    transition: 600ms ease;
}
.registerContent a p{
    margin: 3% 0;
}
.registerContent button#register{
    position: absolute;
    bottom: 5%;
    left: 35%;
    background: none;
    border: 0;
    cursor: pointer;
}








.mainNavContainer.top{
    height: auto;
    width: 100%;
    background: white;
    padding: 0.5vw 4%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(14.3px);
    -webkit-backdrop-filter: blur(14.3px);
}
.navContainer.main{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navContent a{
    text-decoration: none;
}
.navContent.left{
    height: 100%;
    width: 13%;
}
.navContent.left a{
    display: flex;
    justify-content: left;
    align-items: center;
}
.navContent.left img{
    height: 3.5vw;
    max-width: 100%;
    margin-right: 5%;
}
.navContent.left h4{
    font-weight: 600;
    font-size: 1.7vw;
}

.navContent.socials{
    height: auto;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navContent.socials a{
    margin: 0 2%;
}
.navContent.socials a h6{
    font-size: 1.5vw;
    line-height: 1vw;
}

.navContent.right{
    height: 100%;
    width: 65%;
    display: flex;
    justify-content: right;
    align-items: center;
}
.navContent.right a{
    cursor: pointer;
    margin-left: 5%;
    opacity: 50%;
}
.navContent.right a:hover{
    transition: 400ms ease;
    opacity: 100%;
}
.navContent.right button{
    width: 30%;
    background: none;
    border: none;
    margin-left: 7%;
    cursor: pointer;
}
.navContent.right button h5{
    font-size: 1.5vw;
    line-height: 1vw;
}
.navContent.right button#loginButton{
    border: solid 2px black;
    padding: 0.3vw 1vw;
    border-radius: 50px;
}
.navContent.right button#loginButton:hover{
    background: black;
    transition: 600ms ease;
}
.navContent.right button#loginButton:hover h6{
    color: white;
    transition: 600ms ease;
}
.navContent.right button#registerButton{
    margin-left: 2%;
}



.mainContainer.sideNav{
    position: absolute;
    height: 100vh;
    width: 23%;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 2%;
    padding-left: 3%;
    animation: fade-in-right 500ms ease-in-out;
}
.mainContainer.sideNav a{
    text-decoration: none;
}
.mainContainer.sideNav a h6{
    margin-bottom: 6%;
    font-size: 1.2vw;
    opacity: 30%;
    padding: 0.3vw 1vw;
    display: flex;
    justify-content: left;
    align-items: center;
}
.mainContainer.sideNav a h6 .faIcons{
    margin-right: 5%;
}
.mainContainer.sideNav a h6:hover{
    opacity: 100%;
    transition: 600ms ease;
    background: whitesmoke;
    box-shadow: 0 0 5px black;
    border-radius: 0.5vw;
}


@keyframes fade-in-right {
    0% {
      opacity: 0;
      transform: translateX(80px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
}


/* Navigation Below Header Navigation */

/* .mainNavContainer.second{
    height: auto;
    width: 100%;
}
.navContainer.topics{
    height: 3vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 1vw 3%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(14.3px);
    -webkit-backdrop-filter: blur(14.3px);
}
.navContainer.topics a{
    text-decoration: none;
    opacity: 50%;
}
.navContainer.topics a:hover{
    opacity: 100%;
    transition: 600ms ease;
}
.navContainer.topics div{
    width: 13%;
    display: flex;
    justify-content: right;
}
.navContainer.topics button#logButton{
    padding: 0.3vw 1vw;
    border: solid 1px black;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 7%;
}
.navContainer.topics button#logButton:hover{
    background: black;
    transition: 400ms ease;
}
.navContainer.topics button#logButton:hover h6{
    color: white;
    transition: 400ms ease;
}
.navContainer.topics button#regButton{
    background: none;
    border: none;
    cursor: pointer;
} */





.footer {
    width: 100%;
    justify-content: space-between;
    bottom: 0;
    background: whitesmoke;
}
  
.footer-top {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding: 2% 5%;
}
.footer-logo {
    width: 25vw;
}
  
.footer-top p {
    font-size: 15px;
    opacity: 0.7;
    margin-bottom: 20px;
}
  
.footer-top .box {
    width: 50%;
}
.footer-top .box:nth-child(2){
    text-align: left;
}
  
.footer input {
    padding: 10px 20px;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 40px; /* Set a fixed height for all input elements */
}
  
.footer input[type=email] {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(245, 244, 244);
    width: 70%;
}
.footer input[type=submit] {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(158, 8, 8);
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 5px auto;
    height: 44px; /* Set a fixed height for the submit button */
}
hr {
    width: auto;
}
.footer-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 5%;
}
  
.footer-bottom p {
    opacity: 0.7;
    font-weight: 500;
}
.terms {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.divider {
    width: 1px;
    background-color: black;
    height: 15px; 
    margin: 0 10px; 
}